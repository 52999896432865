import { Button } from '@mui/material'
import clsx from 'clsx'
import styles from './IconButton.module.scss'

interface IconButtonProps extends Pick<React.ComponentProps<typeof Button>, 'color' | 'variant'> {
  icon: JSX.Element
  onClick: React.MouseEventHandler
  className?: string
  disabled?: boolean
}

export const IconButton: React.FC<IconButtonProps> = (props) => {
  const { icon, onClick, className, ...rest } = props

  return (
    <Button
      className={clsx(styles.button, className)}
      onClick={onClick}
      variant="outlined"
      {...rest}
    >
      {icon}
    </Button>
  )
}
