import { gql } from '@apollo/client'

export const GET_PRICES_AND_STOCK_QUERY = gql`
  query getPricesAndStock($sapIds: [String!]!, $warehouseIds: [String!]!) {
    getProducts(sapIds: $sapIds) {
      prices {
        bulkPrice1
        catalogPrice
        currency
        listPrice
        metalNeAddition
        netPrice
        sapId
        strikeThroughPrice
        tecSelect
      }
      sapId
      stock(warehouseIds: $warehouseIds) {
        amount
        sapId
        unit
        warehouseId
      }
      stockAvailabilityEstimate {
        sapId
        minValue
        maxValue
        error
        unit
      }
    }
  }
`
