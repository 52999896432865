import { SvgIcon, Tooltip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { ReactComponent as NonParcelIcon } from 'assets/icon/designsystem/cant_be_packaged.svg'
import { ReactComponent as ScissorsIcon } from 'assets/icon/designsystem/content_cut.svg'
import styles from './Labels.module.scss'
import { TypographyEllipsis } from '../typography/TypographyEllipsis'
import clsx from 'clsx'

const Label: React.FC<{ mobile: boolean; icon: React.FC; title: string }> = (props) => {
  const { title, icon, mobile } = props

  const svg = <SvgIcon component={icon} fontSize={'small'} sx={{ mb: 0.5 }} />

  if (mobile) {
    return <Tooltip title={title}>{svg}</Tooltip>
  }

  return (
    <div className={styles.label}>
      {svg}
      <TypographyEllipsis classes={{ root: styles.titleRoot }} variant="smallTextBold">
        {title}
      </TypographyEllipsis>
    </div>
  )
}

export const isValidLabels = (p: { isSendable: boolean; isCutProduct: boolean }) => {
  return !p.isSendable || p.isCutProduct
}

export const Labels: React.FC<{
  onlyIcons
  isSendable: boolean
  isCutProduct: boolean
  direction: 'vertical' | 'horizontal'
}> = (props) => {
  const { onlyIcons, isSendable, isCutProduct, direction = 'vertical' } = props
  const { t } = useTranslation()
  const labels: JSX.Element[] = []

  if (!isSendable) {
    labels.push(
      <Label
        key="is-sendable"
        mobile={onlyIcons}
        icon={NonParcelIcon}
        title={t<string>('SHOPPING_CART.INFORMATION.NON_PARCEL_DELIVERY')}
      />
    )
  }

  if (isCutProduct) {
    labels.push(
      <Label
        key="is-cut-product"
        mobile={onlyIcons}
        icon={ScissorsIcon}
        title={t<string>('SHOPPING_CART.INFORMATION.CUT_ARTICLES')}
      />
    )
  }

  if (!labels.length) {
    return null
  }

  return <div className={clsx(styles.labels, styles[direction])}>{labels}</div>
}
