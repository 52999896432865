import { FC } from 'react'
import { Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './OfferItemProductTag.module.scss'

type Props = {
  prefix?: string
  title: string
  type?: 'productType'
}

export const OfferItemTextBubble: FC<Props> = (props) => {
  const { type, prefix, title } = props
  return (
    <div className={clsx(styles.state, { [styles.orangeShorter]: type === 'productType' })}>
      {prefix && (
        <Typography
          className={clsx({ [styles.white]: type === 'productType' })}
          variant="smallText"
        >
          {prefix}
        </Typography>
      )}{' '}
      <Typography
        className={clsx({ [styles.white]: type === 'productType' })}
        variant="smallTextBold"
      >
        {title}
      </Typography>
    </div>
  )
}
