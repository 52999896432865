import { StockAvailabilityEstimate } from '@obeta/schema'

export const getStockAvailabilityEstimateString = (
  stockAvailabilityEstimate: StockAvailabilityEstimate,
  grammaticalCase: 'nominative' | null = null // accusative is applied by default, nominative only if specifically chosen
): string => {
  const unitMap: { [key: string]: (minValue: number, maxValue: number) => string } = {
    days: (minValue, maxValue) =>
      'Tag' +
      (minValue !== maxValue || maxValue > 1
        ? grammaticalCase === 'nominative'
          ? 'e'
          : 'en'
        : ''),
    weeks: (minValue, maxValue) => 'Woche' + (minValue !== maxValue || maxValue > 1 ? 'n' : ''),
    unknown: () => '',
  }

  if (stockAvailabilityEstimate && stockAvailabilityEstimate.error === '') {
    const unit = stockAvailabilityEstimate.unit
    const minValue = stockAvailabilityEstimate.minValue
    const maxValue = stockAvailabilityEstimate.maxValue
    const parsedUnit = unitMap[unit](minValue, maxValue)

    if (minValue === maxValue) {
      return `${minValue} ${parsedUnit}`
    } else {
      return `${minValue} - ${maxValue} ${parsedUnit}`
    }
  }
  return ''
}
