import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IMapProductPricesProps } from '../product-price/ProductPrice'
import { BaseCardCounterProps, IBaseProductCard } from './baseTypes'
import { createRenderPricesBlock } from './layouts/renderPricesBlock'
import { IWithCheckbox } from './withCheckbox'
import { ICardWithProduct } from './withProduct'
import { ShoppingCartCounter } from '../articles/ShoppingCartCounter'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { Counter } from '../counter/Counter'
import { PopoverMobileCounter } from '../counter/MobileCounter'
import { Box, Typography } from '@mui/material'
import { Horizontal, Mobile } from './layouts'
import styles from '../product-card/withCheckbox.module.scss'
import { Bottom } from './layouts/Bottom'
import { ElbridgeProductItem } from '@obeta/models/lib/models/Elbridge/ElbridgeProductItem'
import { DropdownTemplatesBase } from '../dropdown-templates/DropdownTemplates'
import { usePopoverState } from '@obeta/data/lib/hooks/usePopoverState'
import { DropdownTemplatesType } from '@obeta/models/lib/models/CartTemplates/CartTemplate'
import { Checkbox } from '../checkbox/Checkbox'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'
import { ShoppingCartV2 } from '@obeta/models/lib/models'
import { trackClick } from '@obeta/utils/lib/tracking'
import { useDispatch } from 'react-redux'
import { useProductCard } from '@obeta/data/lib/hooks/useProductCard'
import { AddCartContextEnum, addProductToCartGraphQL } from '@obeta/data/lib/actions'

interface ProductItemProps
  extends Omit<
      ICardWithProduct,
      | 'productUnit'
      | 'counter'
      | 'onAddClicked'
      | 'onInfoClicked'
      | 'product'
      | 'onDeleteClicked'
      | 'productAmount'
      | 'stocks'
      | 'layout'
      | 'settings'
    >,
    IWithCheckbox {
  changeProductAmount: (item: ElbridgeProductItem, amount: number) => void
  productItem: ElbridgeProductItem
  onDeleteClicked: (productItem: ElbridgeProductItem) => void
  loadingPrices?: boolean
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  onCounterValidation?: BaseCardCounterProps['onInputValidation']
  selectedStore: StoreV2 | undefined
  user: UserV2 | null
  isLoggedIn: boolean
  mobile: boolean
  tablet: boolean
  withCheckbox: boolean
  selected: boolean
  selectedCart: ShoppingCartV2 | undefined
  onChange: ChangeEventHandler<HTMLInputElement>
}

export interface ProductItemForPdfProps {
  isCartItemForPdf?: boolean
  shoppingCartItemAmount?: number
  shoppingCartItemMinimumAmount?: number
  isCartItemForOrderPdf?: boolean
  orderPdfSupplierData?: { type: string; value: React.ReactText }
  orderWithPrice?: boolean
  orderItemAmount?: number
}

export const makeElbridgeOrFastProductEntryItem = (
  Card: React.FC<IBaseProductCard & ProductItemForPdfProps>
): React.FC<ProductItemProps & ProductItemForPdfProps> => {
  return (props) => {
    const {
      shoppingCartItemAmount,
      shoppingCartItemMinimumAmount,
      productItem,
      onDeleteClicked,
      changeProductAmount,
      properties = [],
      loadingPrices,
      onProductImageClicked,
      onCounterValidation,
      selectedStore,
      isCartItemForPdf,
      isCartItemForOrderPdf,
      orderWithPrice,
      user,
      isLoggedIn,
      mobile,
      tablet,
      withCheckbox,
      selected,
      selectedCart,
      onChange,
      orderPdfSupplierData,
      orderItemAmount,
      ...restCardProps
    } = props
    const layoutType = 'horizontal'
    const dispatch = useDispatch()
    const { setAmountRoundUpNotification } = useProductCard()
    const { t } = useTranslation()
    const { handleClick: templatesDropdownClickHandler, ...dropdownTemplates } = usePopoverState()

    const lp = Boolean(loadingPrices)

    const priceDimension = productItem.product?.priceDimension
    const netPricePerUnit = (productItem.product.prices?.netPrice || 0) / priceDimension
    const strikeThroughPriceUnit =
      (productItem.product.prices?.strikeThroughPrice || 0) / priceDimension

    const prices: IMapProductPricesProps['prices'] = [
      {
        type: 'PurchasePrice',
        value: productItem.product.prices?.netPrice,
        loading: lp,
        unit: productItem.product.unit,
        oldValue: productItem.product.prices?.strikeThroughPrice,
      },
      {
        type: 'TotalPrice',
        value: netPricePerUnit * productItem.amount,
        oldValue: strikeThroughPriceUnit * productItem.amount,
        loading: lp,
        unit: '',
      },
    ]

    if (mobile || tablet) {
      prices.shift() //remove netPrice from price array
    }

    if (productItem.product.prices?.metalNeAddition) {
      let metalValue = productItem.product.prices?.metalNeAddition

      // Note: For Order PDFs we get metalPrices via orderItem.orderItemPrices.priceMetal and cast it into
      // shoppingCartItem.prices, hence there is no need to recalculate the metal addition
      if (!isCartItemForOrderPdf) {
        const metalNeAdditionPerUnit = productItem.product.prices?.metalNeAddition / priceDimension
        metalValue = metalNeAdditionPerUnit * productItem.amount
      }
      prices.push({
        type: 'MetalAdition',
        value: metalValue,
        loading: lp,
        unit: '', // we don`t want to display units for product in shopping cart
      })
    }
    const [productAmount, setProductAmount] = useState(productItem.amount)

    const [showMobileCounter, setShowMobileCounter] = useState(false)
    const mobileCounterAvailable = Boolean(mobile || tablet)

    useEffect(() => {
      if (productItem.amount !== productAmount) {
        setProductAmount(productItem.amount)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productItem.amount])

    useEffect(() => {
      if (productItem.amount === productAmount) {
        // make sure that update is not called unnecessarily
        return
      }
      changeProductAmount(productItem, productAmount)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productAmount, productItem.id])

    const onAddToCart = useCallback(
      (cart: ShoppingCartV2, amount?: number) => {
        trackClick('elbridge-or-fast-product-entry-item-add-to-cart', {
          orderItemId: productItem.id,
          cartId: cart.id,
          amount,
        })

        if (amount) {
          dispatch(
            addProductToCartGraphQL({
              cartId: cart.id,
              items: [
                {
                  sapId: productItem.product.sapId,
                  amount,
                  title: productItem.product.title,
                },
              ],
              context: AddCartContextEnum.Generic,
            })
          )
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [productItem]
    )

    const onCounterSubmit = useCallback(
      (amount: number) => {
        if (selectedCart) {
          onAddToCart(selectedCart, amount)
        }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [selectedCart]
    )

    const productUnit = productItem.product.unit
    const minimumAmount = productItem.product.minimumAmount
    const minimumAmountInfoText = `Mindestens ${minimumAmount} ${productUnit}`

    const stocksMap = useStocksMap({
      isOnlineCurrentNotAvailable: productItem.product.isCurrentlyNotAvailable,
      stocks: productItem.product.stock,
      user,
    })

    const leftAdornments = withCheckbox
      ? [
          <Checkbox
            key={productItem.id}
            checked={selected}
            className={styles.checkbox}
            onChange={onChange}
          />,
        ]
      : null

    const actions: JSX.Element[] = []
    const topAdornments: JSX.Element[] = []

    return (
      <>
        <Card
          storeAddress={selectedStore?.address.name1 ?? ''}
          authenticated={isLoggedIn}
          title={productItem.product.title}
          properties={[
            {
              type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
              value: productItem.product.dehaId,
            },
            ...properties,
          ]}
          isSendable={productItem.product.isSendable}
          isCutProduct={productItem.product.isCutProduct}
          productImage={{
            componentType: 'card',
            src: productItem.product.imageData.images[0]?.large,
            alt: productItem.product.title,
            supplierImage: productItem.product.supplierImageData?.large,
            supplierId: productItem.product.supplierId,
            oxomiId: productItem.product.oxomiId,
            className: '',
            onClick: onProductImageClicked,
          }}
          productType={productItem.product.type}
          stocks={stocksMap}
          stockAvailabilityEstimate={productItem.product.stockAvailabilityEstimate}
          onInfoClicked={null}
          productAmount={productAmount}
          onAddClicked={(e) => {
            e.stopPropagation()
            templatesDropdownClickHandler(e)
          }}
          counter={
            isCartItemForPdf || !isLoggedIn ? null : mobile || tablet ? (
              <Box sx={{}}>
                <Counter
                  stretchHorizontal={true}
                  amount={productAmount}
                  minimumAmount={minimumAmount || 0}
                  initialAmount={null}
                  variant={'big'}
                  readonly={mobileCounterAvailable}
                  disabled={false}
                  onInputValidation={(valid, amountToUse, amount) => {
                    if (!valid && productItem.product) {
                      setAmountRoundUpNotification(
                        productItem.product.sapId,
                        amountToUse,
                        amount,
                        productItem.product?.unit
                      )
                    }
                  }}
                  changeProductAmount={setProductAmount}
                  onSubmit={onCounterSubmit}
                  onTextFieldClicked={() => {
                    setShowMobileCounter(true)
                  }}
                />
                <Box textAlign={mobile ? 'center' : 'left'}>
                  <Typography color={'text.secondary'} variant={'smallText'}>
                    {minimumAmountInfoText}
                  </Typography>
                </Box>
                {mobileCounterAvailable && (
                  <PopoverMobileCounter
                    open={showMobileCounter}
                    onClose={function () {
                      setShowMobileCounter(false)
                    }}
                    unit={productItem.product.unit}
                    initialAmount={productAmount}
                    minimumAmount={minimumAmount || 0}
                    maxAcceptableAmount={9999}
                    onAccept={function (value: number): void {
                      setProductAmount(value)
                      setShowMobileCounter(false)
                    }}
                  />
                )}
              </Box>
            ) : (
              <ShoppingCartCounter
                shoppingCartItem={productItem}
                unit={productUnit}
                key="counter"
                minimumAmount={minimumAmount}
                amount={productAmount}
                changeProductAmount={setProductAmount}
                initialAmount={null}
                onInputValidation={onCounterValidation}
              />
            )
          }
          onDeleteClicked={
            isCartItemForPdf || withCheckbox ? null : () => onDeleteClicked(productItem)
          }
          isCartItemForPdf={isCartItemForPdf}
          isCartItemForOrderPdf={isCartItemForOrderPdf}
          settings={{
            labels: 'only-icon',
            statusesDirection: mobile ? 'vertical' : 'horizontal',
            stretch: true,
          }}
          layout={(layoutProps) => {
            const bottomAdornments = null
            const renderPricesBlock = createRenderPricesBlock(
              {
                prices,
                pricePermissions: user?.permissions?.Global_canReadPrices,
                currency: productItem.product.prices?.currency || '',
                priceDimension,
              },
              layoutType,
              isLoggedIn,
              !!isCartItemForPdf,
              isCartItemForOrderPdf,
              orderWithPrice
            )
            //layoutProps.actionsElements.unshift(<Action key="add" icon={<PlaylistAddIcon />} onClick={onAddClicked} />)
            const actionsElements = layoutProps.actionsElements.concat(actions)

            if (mobile) {
              return (
                <Mobile
                  {...layoutProps}
                  leftAdornments={leftAdornments}
                  topAdornments={topAdornments}
                  bottomAdornments={bottomAdornments}
                  actionsElements={actionsElements}
                  imageSize={
                    layoutProps.authenticated
                      ? IMAGE_SIZE_AUTHENTICATED
                      : IMAGE_SIZE_UNAUTHENTICATED
                  }
                  values={renderPricesBlock()}
                />
              )
            }

            if (tablet) {
              const { counter, ...restLayoutProps } = layoutProps
              return (
                <Horizontal
                  {...restLayoutProps}
                  className={styles.tablet}
                  counter={null}
                  leftAdornments={leftAdornments}
                  topAdornments={topAdornments}
                  bottomAdornments={bottomAdornments}
                  bottomRightAdornments={[
                    <Bottom
                      key="bottom-adornments"
                      className={styles.bottomTablet}
                      direction="horizontal"
                    >
                      {counter}
                    </Bottom>,
                  ]}
                  mobile={true}
                  actionsElements={actionsElements}
                  mapValues={renderPricesBlock}
                />
              )
            }

            return (
              <Horizontal
                {...layoutProps}
                leftAdornments={leftAdornments}
                topAdornments={topAdornments}
                bottomRightAdornments={bottomAdornments}
                bottomAdornments={null}
                mobile={true}
                actionsElements={actionsElements}
                mapValues={renderPricesBlock}
                isCartItemForPdf={isCartItemForPdf}
                isCartItemForOrderPdf={isCartItemForOrderPdf}
                shoppingCartItemMinimumAmount={shoppingCartItemMinimumAmount}
                shoppingCartItemAmount={shoppingCartItemAmount}
                orderPdfSupplierData={orderPdfSupplierData}
                orderWithPrice={orderWithPrice}
                orderItemAmount={orderItemAmount}
              />
            )
          }}
          {...restCardProps}
        />
        <DropdownTemplatesBase
          dropdown={dropdownTemplates}
          mobile={false}
          productsToAdd={[{ productId: productItem.sapId, amount: productAmount }]}
          templatesType={DropdownTemplatesType.ADD_ARTICLES}
        />
      </>
    )
  }
}
