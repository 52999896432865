import React from 'react'
import styles from './ArticleId.module.scss'
import { Stack, Typography } from '@mui/material'
import stylesForPdf from '../shoppingcart/pdf/ShoppingCartPDFNoOrderYet.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import clsx from 'clsx'

interface Props {
  type: string
  value: React.ReactText
  isCartItemForPdf?: boolean
}

// <ArticleId sapId='1234567890'/>
// <ArticleId dehaId='12345678'/>
export const ArticleId: React.FC<Props> = (props) => {
  const { type, value, isCartItemForPdf } = props
  const { mobile } = useBreakpoints()

  return (
    <Stack className={styles.articleId} direction={'row'}>
      <Typography
        variant={!mobile ? 'body' : 'smallText'}
        className={clsx(styles.ArticleIdLabel, {
          [stylesForPdf.fontSize12px]: isCartItemForPdf,
        })}
      >
        {type}:&nbsp;
      </Typography>
      <Typography
        variant={!mobile ? 'bodyBold' : 'smallTextBold'}
        className={isCartItemForPdf ? stylesForPdf.fontSize12px : ''}
      >
        {value}
      </Typography>
    </Stack>
  )
}
