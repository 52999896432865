import clsx from 'clsx'
import React from 'react'
import styles from './Actions.module.scss'
import { LayoutType } from './types'

export const ActionsWrapper: React.FC<{
  layout: LayoutType
  largeIcons?: boolean
}> = (props) => {
  return <div className={clsx(styles.actions, styles[props.layout])}>{props.children}</div>
}
