import React from 'react'
import { Box, Typography } from '@mui/material'
import { Counter, CounterProps } from '../counter/Counter'

export interface ArticleAmountProps extends Omit<CounterProps, 'variant' | 'disabled'> {
  unit: string
  deadProduct?: boolean
  disableCounter?: boolean
  onCounterSubmit?: (amount: number, userInputAmount: number) => void
}

export const ArticleAmount: React.FC<ArticleAmountProps> = (props) => {
  const { minimumAmount, unit, deadProduct, disableCounter, onCounterSubmit, ...counterRest } =
    props
  const minimumAmountInfoText = `Mindestens ${minimumAmount} ${unit}`
  const disabled = false

  return (
    <Box position={'relative'}>
      <Counter
        minimumAmount={minimumAmount}
        variant={'small'}
        disabled={disableCounter ?? disabled}
        onSubmit={onCounterSubmit}
        {...counterRest}
      />
      <Typography marginTop={'.25rem'} color={'text.secondary'} variant={'smallText'}>
        &nbsp;{' '}
        {/*this is just used as a spacer for the following absolutley aligned real text
         * and helps to realise a proper overflow state without adding to the parent's width
         * as the boxes should define them
         * */}
      </Typography>
      <Typography
        position={'absolute'}
        marginTop={'.25rem'}
        color={'text.secondary'}
        variant={'smallText'}
        overflow={'hidden'}
        textOverflow={'ellipsis'}
        width={'100%'}
        bottom={0}
        left={0}
        whiteSpace={'nowrap'}
      >
        {!deadProduct && !disableCounter && minimumAmountInfoText}
      </Typography>
    </Box>
  )
}
