import { LocationDescriptorObject } from 'history-types'
import { ShopRoutes } from '../variables'

export const buildLocationStateWithDehaIds = (
  location: LocationDescriptorObject<unknown>,
  replacementDehaIds: string[],
  customTitle?: string,
  productDehaIdsFilterLabel?: string
): LocationDescriptorObject<unknown> => {
  const searchParams = {
    id: '',
    productDehaIds: replacementDehaIds,
    customTitle,
    productDehaIdsFilterLabel,
  }

  return {
    ...location,
    pathname: ShopRoutes.Search,
    state: {
      searchParams,
      pagination: { currentPage: 0 },
    },
  }
}
