import React from 'react'
import { SvgIcon, Typography } from '@mui/material'
import styles from './ActionsRectangle.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useTranslation } from 'react-i18next'
import { ReplacementArticleLink } from '../product-card/ReplacementArticleLink'
import { ReactComponent as DeleteIcon } from 'assets/icon/designsystem/delete_outline.svg'
import { TertiaryButton, TertiaryIconButton } from '../custom-button/CustomButton'
import { ShoppingCartItem } from '@obeta/models/lib/models'
import { ReactComponent as ArrowsLeftRightIcon } from '@obeta/assets/icon/designsystem/swap_horiz.svg'

interface IActionsRectangleProps {
  replacementArticleHref: string
  showReplacementSection?: boolean
  onDeleteClicked: (e: React.MouseEvent<HTMLElement>) => void
  deadProductItem: ShoppingCartItem
  setOpenReplacementArticleDialog?: (p: boolean) => void
}

//TODO: ReplacementArticleLink should only be shown if it is relevant (Verkaufsartikel “NEIN” - im Warenkorb.)
export const ActionsRectangle: React.FC<IActionsRectangleProps> = (props) => {
  const {
    replacementArticleHref,
    showReplacementSection,
    onDeleteClicked,
    setOpenReplacementArticleDialog,
  } = props
  const { desktop } = useBreakpoints()
  const { t } = useTranslation()

  return (
    <div className={styles.containerDiv}>
      {showReplacementSection ? (
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={ArrowsLeftRightIcon} />}
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            setOpenReplacementArticleDialog && setOpenReplacementArticleDialog(true)
          }}
        >
          {desktop && (
            <Typography className={styles.infoReplacementText}>
              {t('INFO.REPLACEMENT_AVAILABLE')}
            </Typography>
          )}
        </TertiaryButton>
      ) : desktop ? (
        replacementArticleHref && <ReplacementArticleLink href={replacementArticleHref} compact />
      ) : (
        replacementArticleHref && (
          <TertiaryIconButton
            icon={<ReplacementArticleLink href={replacementArticleHref} compact />}
            size={'large'}
          />
        )
      )}
      <div className={styles.deleteIcon}>
        <TertiaryIconButton
          icon={<SvgIcon component={DeleteIcon} />}
          size={desktop ? 'small' : 'large'}
          onClick={onDeleteClicked}
        />
      </div>
    </div>
  )
}
