import { Typography, TypographyProps } from '@mui/material'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { FC, useEffect, useRef, useState } from 'react'
import { Tooltip } from '../tooltip/Tooltip'
import styles from './OverflowTooltipTypography.module.scss'

interface IOverflowTooltipTypography {
  text: string
  overflowVariantProps?: TypographyProps & { htmlFor?: string }
  hoverVariantProps?: TypographyProps & { htmlFor?: string }
}

export const OverflowTooltipTypography: FC<IOverflowTooltipTypography> = (props) => {
  const { text, overflowVariantProps, hoverVariantProps } = props
  const [isOverflow, setIsOverflow] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const textRef = useRef<HTMLSpanElement>(null)
  const { desktop } = useBreakpoints()

  useEffect(() => {
    const checkTextOverflow = () => {
      if (textRef.current && containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth
        const textWidth = textRef.current.offsetWidth
        setIsOverflow(textWidth > containerWidth)
      }
    }

    checkTextOverflow()
    window.addEventListener('resize', checkTextOverflow)

    return () => {
      window.removeEventListener('resize', checkTextOverflow)
    }
  }, [text])

  return (
    <div ref={containerRef} className={styles.container}>
      {isOverflow ? (
        <Tooltip title={text} activateOnClick={!desktop}>
          <Typography
            ref={textRef}
            noWrap
            variant="body"
            className={desktop ? '' : styles.underline}
            {...overflowVariantProps}
          >
            {text}
          </Typography>
        </Tooltip>
      ) : (
        <Typography ref={textRef} noWrap variant="body" {...hoverVariantProps}>
          {text}
        </Typography>
      )}
    </div>
  )
}
