import { ButtonBase } from '@mui/material'
import { MouseEventHandler } from 'react'

export const Action: React.FC<{
  icon: JSX.Element
  onClick: MouseEventHandler
  className?: string
}> = (props) => {
  const { onClick, icon } = props
  return <ButtonBase onClick={onClick}>{icon}</ButtonBase>
}
