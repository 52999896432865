import { useHistory } from '@obeta/data/lib/hooks/useHistoryApi'
import { ShopRoutes } from '@obeta/utils/lib/variables'
import { IBaseProductCard } from './baseTypes'

/**
 * withLinkToArticleDetail
 *
 * @description
 * Adds `onProductImageClicked` and `onTitleClick` props to the component
 * and sets the `history.push` to the ArticleDetailPage with the productId as a param.
 *
 * @param Card - the component which will be wrapped with the link
 * @param getProductId - a function which takes the props of the component and returns the productId
 * @returns The wrapped, clickable, component with `onProductImageClicked` and `onTitleClick` props.
 */
export const withLinkToArticleDetail = <
  Props extends {
    onProductImageClicked?: IBaseProductCard['productImage']['onClick']
    onTitleClick?: IBaseProductCard['onTitleClick']
  }
>(
  Card: React.FC<Props>,
  getProductId: (props: Omit<Props, 'onProductImageClicked' | 'onTitleClick'>) => string
): React.FC<Omit<Props, 'onProductImageClicked' | 'onTitleClick'>> => {
  return (props) => {
    const history = useHistory()

    const push = () => {
      history.push(`${ShopRoutes.ArticleDetailPage}/${getProductId(props)}`)
    }

    return <Card {...(props as Props)} onProductImageClicked={push} onTitleClick={push} />
  }
}
