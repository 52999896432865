import React from 'react'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import { ReactComponent as ArrowsLeftRightIcon } from '@obeta/assets/icon/designsystem/swap_horiz.svg'
import styles from './ReplacementArticleLink.module.scss'
import { ShopLink } from '../link/ShopLink'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'

interface Props {
  href: string
  className?: string
  compact?: boolean
}
export const ReplacementArticleLink: React.FC<Props> = ({ href, className, compact }) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const showText = desktop || !compact

  return (
    <ShopLink
      href={href}
      color={'inherit'}
      underline={'none'}
      maxWidth={'100%'}
      className={clsx(styles.replacementArticle, className)}
      trackingActionName="replacement-article"
    >
      <ArrowsLeftRightIcon className={styles.arrowLeftRight} />
      {showText && (
        <Typography className={styles.replacementArticleText}>
          {t('INFO.REPLACEMENT_AVAILABLE')}
        </Typography>
      )}
    </ShopLink>
  )
}
