import { FC, ReactText } from 'react'
import styles from '../product-card/withCheckbox.module.scss'
import { Box } from '@mui/material'
import { Counter } from '../counter/Counter'
import { Horizontal, Mobile } from './layouts'
import { IBaseProductCard } from './baseTypes'
import { ICardWithProduct } from './withProduct'
import { IMAGE_SIZE_AUTHENTICATED, IMAGE_SIZE_UNAUTHENTICATED } from './Base'
import { OrderItemForDetailsPage } from '@obeta/models/lib/schema-models/order-details'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { RemovedPosition } from './RemovedPosition'
import { TabletSmall } from '../product-card/layouts/TabletSmall'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { useTranslation } from 'react-i18next'
import { OrderItemForInArrearsPage } from '@obeta/models/lib/schema-models/order-in-arrears'

interface UnknownOrderItemProps
  extends Omit<
    ICardWithProduct,
    | 'counter'
    | 'changeProductAmount'
    | 'onAddClicked'
    | 'onDeleteClicked'
    | 'onInfoClicked'
    | 'layout'
    | 'product'
    | 'productAmount'
    | 'productUnit'
    | 'selected'
    | 'selectedCart'
    | 'shippingValue'
    | 'settings'
    | 'stocks'
  > {
  isLoggedIn: boolean
  mobile: boolean
  orderItem: OrderItemForDetailsPage | OrderItemForInArrearsPage
  tablet: boolean
  tabletWide: boolean
  user: UserV2 | null
}

export interface OrderItemForPdfProps {
  isOrderItem?: boolean
  orderItemAmount?: number
  orderItemShippingAmount?: number
  orderPdfSupplierData?: { type: string; value: ReactText }
  orderWithPrice?: boolean
  shoppingCartItemMinimumAmount?: number
}
export const makeUnknownOrderItem = (
  Card: FC<IBaseProductCard & OrderItemForPdfProps>
): FC<UnknownOrderItemProps & OrderItemForPdfProps> => {
  return (props) => {
    const {
      isLoggedIn,
      isOrderItem,
      mobile,
      orderItem,
      orderItemAmount,
      orderPdfSupplierData,
      orderWithPrice,
      properties = [],
      tablet,
      tabletWide,
      user,
      ...restCardProps
    } = props
    const { t } = useTranslation()
    const mobileOrTabletAll = mobile || tablet || tabletWide

    const propertiesForDisplay = [
      {
        type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'),
        value: orderItem.obetaId ?? orderItem.dehaId,
      },
      ...properties,
    ]

    return (
      <div className={styles.unknownOrderItemCardWrapper}>
        <Card
          onDeleteClicked={null}
          {...restCardProps}
          storeAddress={''}
          authenticated={isLoggedIn}
          title={orderItem.articleDescription}
          properties={propertiesForDisplay}
          isSendable={true}
          isCutProduct={false}
          productImage={{
            componentType: 'card',
            src: '',
            alt: orderItem.articleDescription,
            supplierImage: orderItem.supplierImageData.large,
            supplierId: orderItem.supplierImageData.sapId,
            oxomiId: '',
            className: '',
            onClick: undefined,
          }}
          productType={ProductType.normal}
          showStockData={false}
          stocks={null}
          onAddClicked={null}
          onInfoClicked={null}
          showIdsTransferButton
          productAmount={orderItem.orderAmount}
          counter={
            !isLoggedIn ? null : (
              <Box>
                <Counter
                  stretchHorizontal={true}
                  amount={orderItem.orderAmount}
                  minimumAmount={0}
                  initialAmount={null}
                  variant={mobileOrTabletAll ? 'big' : 'small'}
                  disabled={true}
                  readonly={mobileOrTabletAll}
                  changeProductAmount={() => {
                    //
                  }}
                  onTextFieldClicked={() => {
                    //
                  }}
                />
              </Box>
            )
          }
          isOrderItem={true}
          settings={{
            labelDirection: mobile ? 'vertical' : 'horizontal',
            labels: 'only-icon',
            statusesDirection: mobile ? 'vertical' : 'horizontal',
            stretch: true,
          }}
          titleLines={mobile ? 6 : 2}
          layout={(layoutProps) => {
            if (mobile) {
              return (
                <Mobile
                  {...layoutProps}
                  actionsElements={[]}
                  bottomAdornments={null}
                  deadProduct
                  imageSize={
                    layoutProps.authenticated
                      ? IMAGE_SIZE_AUTHENTICATED
                      : IMAGE_SIZE_UNAUTHENTICATED
                  }
                  leftAdornments={null}
                  topAdornments={null}
                  values={null}
                />
              )
            }

            if (tablet) {
              const { counter, propertiesElements, ...restLayoutProps } = layoutProps
              return (
                <TabletSmall
                  {...restLayoutProps}
                  actionsElements={[]}
                  bottomAdornments={null}
                  bottomRightAdornments={null}
                  propertiesElements={[
                    <div className={styles.unknownOrderItemProperties}>
                      {propertiesElements}
                      {counter}
                    </div>,
                  ]}
                  counter={null}
                  deadProduct={true}
                  isOrderItem={true}
                  leftAdornments={null}
                  mapValues={null}
                  mobile={mobile}
                  orderItemAmount={orderItem.orderAmount}
                  orderItemUnit={orderItem.unit ?? ''}
                  topAdornments={null}
                />
              )
            }

            return (
              <Horizontal
                {...layoutProps}
                actionsElements={[]}
                bottomAdornments={null}
                bottomRightAdornments={null}
                deadProduct={true}
                isOrderItem={true}
                leftAdornments={null}
                labelsInAdditionalValues={false}
                mapValues={null}
                mobile={mobile}
                orderItemAmount={orderItem.orderAmount}
                orderPdfSupplierData={orderPdfSupplierData}
                orderWithPrice={false}
                topAdornments={null}
              />
            )
          }}
          {...restCardProps}
        />

        <RemovedPosition />
        <div className={styles.unknownOrderItemOverlay} />
      </div>
    )
  }
}
