import { MaybeCompleteSearchProduct, CompleteSearchProduct } from '..'

export const isCompleteSearchProduct = (
  item: MaybeCompleteSearchProduct
): item is CompleteSearchProduct => {
  return (
    (item as CompleteSearchProduct).prices !== undefined &&
    (item as CompleteSearchProduct).stock !== undefined
  )
}
