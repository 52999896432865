import clsx from 'clsx'
import React from 'react'
import { ProductImgSize } from '.'
import styles from './ProductImage.module.scss'

export const ProductImage: React.FC<{
  size: ProductImgSize | null
}> = (props) => {
  return (
    <div className={clsx(styles.root, { [styles[`size${props.size}`]]: props.size !== null })}>
      {props.children}
    </div>
  )
}
