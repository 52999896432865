import { Paper, Typography } from '@mui/material'
import clsx from 'clsx'
import styles from './Tag.module.scss'
import { ReactComponent as LocalFireDepartmentIcon } from '@obeta/assets/icon/local_fire_department.svg'

interface ITagProps {
  color?: 'red100pct'
  icon: JSX.Element
}

export const Tag: React.FC<ITagProps> = (props) => {
  const { color = 'red100pct', icon } = props

  return (
    <Paper className={clsx(styles.tag, { [styles.red100pct]: color === 'red100pct' })}>
      {icon}
      <Typography color="" variant="smallTextBold">
        {props.children}
      </Typography>
    </Paper>
  )
}

export const TopsellerTag: React.FC = () => {
  return <Tag icon={<LocalFireDepartmentIcon />}>Topseller</Tag>
}
