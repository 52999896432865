import clsx from 'clsx'
import styles from './Bottom.module.scss'

export const Bottom: React.FC<{ className?: string; direction?: 'vertical' | 'horizontal' }> = (
  props
) => {
  const { className, children, direction = 'vertical' } = props

  return <div className={clsx(styles.bottomElement, styles[direction], className)}>{children}</div>
}
