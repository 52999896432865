import { ShoppingCartV2 } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { useMemo } from 'react'
import { useEntities } from './useEntities'
import { useUserV2 } from './useUserV2'
import { useSessionCart } from './useSessionCart'
import { getSessionContext } from '@obeta/utils/lib/session-context'

export const useSelectedCart = (): ShoppingCartV2 | undefined => {
  const user = useUserV2()
  const sessionCart = useSessionCart()
  const sessionContext = getSessionContext()
  const selectedUserQuery = useMemo(() => {
    const selectedCartId =
      sessionCart && sessionContext?.type === 'oci' ? sessionCart.id : user?.settings?.defaultCartId
    if (!selectedCartId) {
      return undefined
    }

    return {
      selector: {
        id: selectedCartId,
      },
    }
  }, [sessionCart, sessionContext?.type, user?.settings?.defaultCartId])
  return useEntities<ShoppingCartV2>('cartsv2', selectedUserQuery)[0]
}
