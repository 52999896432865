import { IShopProductCardProps, ShopProductCard } from '.'

export const ArticleAccessoriesCard: React.FC<
  Omit<IShopProductCardProps, 'onTitleClick' | 'onProductImageClicked' | 'withListPrice' | 'layout'>
> = (props) => {
  return (
    <ShopProductCard
      {...props}
      withListPrice={true}
      noTopAdornments={true}
      layout="horizontal"
      url={
        'images' in props.product && props.product.images && props.product.images?.length > 0
          ? props.product.images[0].url
          : ''
      }
    />
  )
}
