import React from 'react'
import { Typography } from '@mui/material'
import styles from './RemovedPosition.module.scss'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BlockIcon } from 'assets/icon/designsystem/block.svg'

interface IRemovedPositionProps {
  children?: JSX.Element
}

export const RemovedPosition: React.FC<IRemovedPositionProps> = (props) => {
  const { children } = props
  const { t } = useTranslation()

  return (
    <div className={clsx(styles.actionsRectangle)}>
      <div className={styles.contentWrapper}>
        <div className={clsx(styles.flexed, styles.evenMargin)}>
          <BlockIcon className={clsx(styles.blockIcon, styles.centered)} />
          <div className={styles.text}>
            <Typography variant={'bodyBold'}>
              {`${t('DEAD_PRODUCTS.NO_AVAILABILITY')} -`}
            </Typography>
            <Typography variant="body">{t('DEAD_PRODUCTS.PRODUCT_NOT_EXTENDED')}</Typography>
          </div>
        </div>
        {children}
      </div>
    </div>
  )
}
