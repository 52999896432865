import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Typography } from '@mui/material'
import clsx from 'clsx'

// Models
import { OrderItemStateRecord } from '@obeta/models/lib/models/Order/OrderV2'
import { OrderItemState } from '@obeta/schema'

// Styles
import styles from './OrderStateItem.module.scss'

type Props = {
  itemState: OrderItemState
  hideCount?: boolean
}

export const OrderStateItem: FC<Props> = (props) => {
  const { itemState, hideCount } = props

  const { t } = useTranslation()

  if (itemState.type === 'None') {
    return null
  }

  return (
    <Typography
      className={clsx(styles.state, styles[`${OrderItemStateRecord[itemState.type].className}`])}
      variant="smallText"
    >
      {hideCount
        ? t(OrderItemStateRecord[itemState.type].label)
        : `${t(OrderItemStateRecord[itemState.type].label)} (${itemState.count})`}
    </Typography>
  )
}
