import { Typography } from '@mui/material'
import styles from './NoPrice.module.scss'

interface INoPriceProps {
  variant?: React.ComponentProps<typeof Typography>['variant']
  currency: string | undefined | null
}

export const NoPrice: React.FC<INoPriceProps> = (props) => {
  const { currency, variant } = props
  return (
    <Typography className={styles.root} color="text.secondary" variant={variant || 'bodyBold'}>
      - {currency}
    </Typography>
  )
}
