import { OrderItemAmount, OrderItemStateType } from '@obeta/schema'
import { MultiSelectionString } from '../Meta/Meta'

// Display order flag by class name and label
interface OrderFlagInfo {
  className: string
  label: string
}

// Mapped set of possible order flag types
export const OrderItemStateRecord: Record<OrderItemStateType, OrderFlagInfo> = {
  Archived: {
    className: 'archived',
    label: 'ORDERS.STATES.ARCHIVE',
  },
  Canceled: {
    className: 'cancelled',
    label: 'ORDERS.STATES.CANCELLED',
  },
  Credit: {
    className: 'credit',
    label: 'ORDERS.STATES.CREDIT',
  },
  Delivered: {
    className: 'shipped',
    label: 'ORDERS.STATES.SHIPPED',
  },
  InArrears: {
    className: 'inArrears',
    label: 'ORDERS.STATES.IN_ARREARS',
  },
  InDelivery: {
    className: 'inDelivery',
    label: 'ORDERS.STATES.IN_DELIVERY',
  },
  InProgress: {
    className: 'inProgress',
    label: 'ORDERS.STATES.IN_PROGRESS',
  },
  Invoiced: {
    className: 'invoiced',
    label: 'ORDERS.STATES.INVOICED',
  },
  DeliveredPartially: {
    className: 'partialDelivery',
    label: 'ORDERS.STATES.PARTIAL_DELIVERY',
  },
  PartiallyCollected: {
    className: 'partialCollection',
    label: 'ORDERS.STATES.PARTIAL_COLLECTION',
  },
  PartiallyDelivered: {
    className: 'partialDelivery',
    label: 'ORDERS.STATES.PARTIAL_DELIVERY',
  },
  PartiallyReadyForPickUp: {
    className: 'partiallyReadyForPickUp',
    label: 'ORDERS.STATES.PARTIALLY_READY_FOR_PICK_UP',
  },
  ReadyForPickup: {
    className: 'readyForPickUp',
    label: 'ORDERS.STATES.READY_FOR_PICK_UP',
  },
  ReadyForPickUp: {
    className: 'readyForPickUp',
    label: 'ORDERS.STATES.READY_FOR_PICK_UP',
  },
  PickedUp: {
    className: 'pickedUp',
    label: 'ORDERS.STATES.PICKED_UP',
  },
  Shipped: {
    className: 'shipped',
    label: 'ORDERS.STATES.SHIPPED',
  },
  None: {
    className: '',
    label: '',
  },
  // The below is prepared, because needed in later versions, but not included in the schema yet
  // return: {
  //   className: 'return',
  //   label: 'ORDERS.STATES.RETURN',
  // },
}

export interface DropDownTemplatesOrder {
  id: string
  selectedItemCount: number
  multiSelection: MultiSelectionString
  modifiedOrderItemAmounts: OrderItemAmount[]
}
