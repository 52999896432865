import { Box, Typography } from '@mui/material'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'
import styles from './PricesAuthenticateView.module.scss'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { SecondaryLightButton } from '../custom-button/CustomButton'
import { useLogin } from '@obeta/data/lib/hooks/useLogin'

export const PricesAuthenticateView: React.FC<{
  direction?: 'horizontal' | 'vertical'
  gap?: 'xs' | 'md'
}> = (props) => {
  const { direction = 'vertical', gap = 'md' } = props
  const { t } = useTranslation()
  const { mobile } = useBreakpoints()
  const { startLogin } = useLogin()
  const isHorizontalLayout = direction === 'horizontal'

  return (
    <div className={clsx(styles.root, styles[direction], styles[gap])}>
      <Typography
        className={clsx(styles.label, styles[`${direction}Label`], {
          [styles.left]: !mobile && isHorizontalLayout,
        })}
        variant="body"
      >
        {t<string>('ARTICLE_DETAIL.LOGIN_TO_SEE_PRICES')}
      </Typography>
      <Box className={styles.buttonWrapper}>
        <SecondaryLightButton onClick={startLogin}>
          {t<string>('ONBOARDING.LOGIN')}
        </SecondaryLightButton>
      </Box>
    </div>
  )
}
