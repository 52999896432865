import { useCallback } from 'react'
import { gql, useApolloClient } from '@apollo/client'
import {
  GetIdsFormFieldsForProductsQuery,
  GetIdsFormFieldsForProductsQueryVariables,
} from '@obeta/schema'
import { submitIdsPostForm } from '@obeta/utils/lib/idsHelper'
import { getSessionContext } from '@obeta/utils/lib/session-context'
import { IdsProductRequest } from '@obeta/models/lib/schema-models/ids'
import { EventType, getEventSubscription, NotificationType } from '@obeta/utils/lib/pubSub'
import { useTranslation } from 'react-i18next'

type TransferIdsProductAndSubmit = (items: IdsProductRequest[]) => void
type UseIdsProductTransfer = () => TransferIdsProductAndSubmit

const GET_IDS_FORM_FIELDS_FOR_PRODUCTS_QUERY = gql`
  query getIdsFormFieldsForProducts($input: IdsFormFieldsForProductsRequest!) {
    getIdsFormFieldsForProducts(input: $input) {
      success
      errorCode
      errorMessage
      idsFormFields {
        action
        version
        warenkorb
      }
    }
  }
`

export const useIdsProductTransfer: UseIdsProductTransfer = () => {
  const { t } = useTranslation()
  const client = useApolloClient()
  const transferIdsProductAndSubmit: TransferIdsProductAndSubmit = useCallback(
    async (items) => {
      const sessionContext = getSessionContext()

      const response = await client.query<
        GetIdsFormFieldsForProductsQuery,
        GetIdsFormFieldsForProductsQueryVariables
      >({
        query: GET_IDS_FORM_FIELDS_FOR_PRODUCTS_QUERY,
        variables: {
          input: {
            idsProductInfos: items,
          },
        },
      })
      if (
        response &&
        response.data.getIdsFormFieldsForProducts.success &&
        response.data.getIdsFormFieldsForProducts.idsFormFields &&
        sessionContext
      ) {
        submitIdsPostForm(
          response.data.getIdsFormFieldsForProducts.idsFormFields,
          sessionContext.hookUrl as string,
          sessionContext.target as string
        )
      } else if (response && response.data.getIdsFormFieldsForProducts.errorMessage) {
        const message =
          response.data.getIdsFormFieldsForProducts.errorMessage.length > 0
            ? response.data.getIdsFormFieldsForProducts.errorMessage
            : t('IDS.ERROR_FALLBACK_MESSAGE')
        getEventSubscription().next({
          type: EventType.Toast,
          notificationType: NotificationType.Error,
          id: 'ids-transfer-back-product-error',
          options: {
            message,
          },
        })
      }
    },
    [client, t]
  )
  return transferIdsProductAndSubmit
}
