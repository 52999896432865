import { ProductAggregate, ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import { StoreV2 } from '@obeta/models/lib/models/Stores/StoreV2'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { IBaseProductCard } from './baseTypes'
import { useStocksMap } from '@obeta/data/lib/hooks/useStocksMap'
import { ICardWithCounterProps, IWithCounter } from './withBaseCounter'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'
import { MaybeCompleteSearchProduct } from '@obeta/models/lib/schema-models/search'
import { RecommendationProduct } from '@obeta/models/lib/schema-models/recommendation'

export interface ICardWithProduct
  extends Omit<
      IBaseProductCard,
      | 'title'
      | 'properties'
      | 'isSendable'
      | 'isCutProduct'
      | 'productImage'
      | 'productType'
      | 'stocks'
      | 'storeAddress'
      | 'counter'
    >,
    Omit<ICardWithCounterProps, 'minimumAmount'>,
    Partial<Pick<IBaseProductCard, 'properties'>> {
  product: ProductAggregate | MaybeCompleteSearchProduct | RecommendationProduct
  onProductImageClicked?: IBaseProductCard['productImage']['onClick']
  onProductImageLoaded?: IBaseProductCard['productImage']['onLoad']
  selectedStore: StoreV2 | undefined
  user: UserV2 | null
}

export const withProduct = (
  Card: React.FC<IWithCounter<IBaseProductCard>>
): React.FC<ICardWithProduct> => {
  return function WithProduct(props) {
    const {
      product,
      properties = [],
      onProductImageClicked,
      selectedStore,
      onProductImageLoaded,
      user,
      ...rest
    } = props
    const { t } = useTranslation()

    let isOnlineCurrentNotAvailable = false
    let stocks
    if ('isCurrentlyNotAvailable' in product) {
      isOnlineCurrentNotAvailable = product.isCurrentlyNotAvailable
    }
    if ('stock' in product) {
      stocks = product.stock
    }
    const stocksMap = useStocksMap({
      isOnlineCurrentNotAvailable,
      stocks,
      user,
    })

    return (
      <Card
        title={product.title}
        properties={[
          { type: t<string>('ARTICLE_DETAIL.ARTICLE_NUMBER'), value: product.dehaId },
          ...properties,
        ]}
        isSendable={product.isSendable}
        isCutProduct={product.isCutProduct}
        productImage={{
          src: product.imageData?.images[0]?.large ?? '',
          alt: product.title,
          supplierImage: product.supplierImageData?.large,
          supplierId: 'supplierId' in product ? product.supplierId : '',
          oxomiId: product.oxomiId,
          className: '',
          onLoad: onProductImageLoaded,
        }}
        productType={product.type as ProductType}
        minimumAmount={product.minimumAmount}
        stocks={stocksMap}
        storeAddress={selectedStore?.address?.name1 || ''}
        dehaId={'dehaId' in product ? product.dehaId : undefined}
        replacementProducts={
          'replacementProducts' in product ? product.replacementProducts : undefined
        }
        {...rest}
      />
    )
  }
}
