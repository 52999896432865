import { Stock, StockType } from '@obeta/models/lib/models/Article/Shop/Product'
import { useWarehouseContext } from '../stores/useWarehouseContext'
import { useMemo } from 'react'
import { UserV2 } from '@obeta/models/lib/models/Users/UserV2'

export type Stocks = Record<
  StockType,
  | {
      available: boolean
      stock: Stock
    }
  | undefined
  | null
> | null

export const useStocksMap = (p: {
  isOnlineCurrentNotAvailable: boolean
  stocks: Stock[] | undefined
  user: UserV2 | null
}): Stocks => {
  const { user, isOnlineCurrentNotAvailable, stocks } = p
  const { warehouseId } = useWarehouseContext()

  const userStoreId = user?.settings?.defaultStoreId || ''
  return useMemo(() => {
    if (!stocks) {
      return null
    }

    const stocksById = stocks.reduce<Record<string, Stock>>((acc, current) => {
      acc[current.warehouseId] = current
      return acc
    }, {})

    const mainStock = stocksById[warehouseId]
    const userStock = stocksById[userStoreId]

    const stocksMap: Stocks = {
      online: {
        available: !isOnlineCurrentNotAvailable,
        stock: mainStock,
      },
      store: {
        available: true,
        stock: userStock,
      },
    }

    return stocksMap
  }, [isOnlineCurrentNotAvailable, stocks, userStoreId, warehouseId])
}
