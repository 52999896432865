import { LayoutType } from '.'
import { PricesAuthenticateView } from '../../prices-authenticate-view/PricesAuthenticateView'
import {
  IMapProductPricesProps,
  MapProductPrices,
  ProductPriceRenderConfig,
} from '../../product-price/ProductPrice'
import { ValuesContainer } from './Values'
import { PricePermissions } from '@obeta/models/lib/models/Users/UserV2'

export const createRenderPricesBlock = (
  pricesData: Pick<
    IMapProductPricesProps,
    'prices' | 'currency' | 'pricePermissions' | 'priceDimension'
  >,
  layoutType: LayoutType,
  authenticated: boolean,
  isToPreparePdf?: boolean,
  isCartItemForOrderPdf?: boolean,
  orderWithPrice?: boolean,
  hideOldPriceElement?: boolean
) => {
  return (additionalValues: JSX.Element[] = [], config?: ProductPriceRenderConfig) => {
    if (!authenticated) {
      return (
        <PricesAuthenticateView
          gap={layoutType === 'horizontal' ? 'md' : 'xs'}
          direction={layoutType}
        />
      )
    }

    if (isToPreparePdf && pricesData.pricePermissions !== PricePermissions.PurchasePrice) {
      return (
        <ValuesContainer
          key={'noPurchasePrice'}
          layoutType={layoutType}
          values={additionalValues}
        />
      )
    }

    if (isCartItemForOrderPdf && !orderWithPrice) {
      return (
        <ValuesContainer
          key={'noPurchasePrice'}
          layoutType={layoutType}
          values={additionalValues}
        />
      )
    }

    const necessaryProps = isToPreparePdf
      ? { ...pricesData, isCartItemForPdf: true, isCartItemForOrderPdf: isCartItemForOrderPdf }
      : { ...pricesData }

    const asManyOrderedAsArray = [additionalValues[additionalValues.length - 1]]
    isCartItemForOrderPdf && additionalValues.pop()

    return (
      <MapProductPrices
        layout={layoutType}
        renderConfig={config}
        {...necessaryProps}
        render={(priceEls) => {
          if (isCartItemForOrderPdf) {
            return (
              <ValuesContainer
                layoutType={layoutType}
                values={asManyOrderedAsArray.concat(priceEls, additionalValues)}
              />
            )
          } else {
            return (
              <ValuesContainer
                layoutType={layoutType}
                values={priceEls.concat(additionalValues)}
                hideOldPriceElement={hideOldPriceElement}
              />
            )
          }
        }}
      />
    )
  }
}
