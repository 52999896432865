import {
  FC,
  ChangeEvent,
  CSSProperties,
  FocusEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import clsx from 'clsx'
import { Paper, Popover, PopoverOrigin, SvgIcon, TextField, Typography } from '@mui/material'
import { TFunction, useTranslation } from 'react-i18next'
import { Select } from '@mobiscroll/react5'
import { IconButton } from '../icon-button/IconButton'
import { ReactComponent as CloseIcon } from '@obeta/assets/icon/designsystem/close.svg'
import { ReactComponent as CheckIcon } from '@obeta/assets/icon/designsystem/check.svg'
import { getNextValidAmount } from './helpers'
import '@obeta/assets/theme/mobiscroll.scss'
import { useKeyboardHeight } from '@obeta/data/lib/hooks/useKeyboardHeight'
import styles from './MobileCounter.module.scss'

export interface IMobileCounterProps {
  minimumAmount: number
  unit: string
  initialAmount?: number
  maxAcceptableAmount: number
  onAccept: (value: number) => void
  onCancel: (value: number) => void
  rowsCount?: number
}

interface IDataItem {
  value: number
}

interface IRowRendererContext {
  minimumAmount: number
  unit?: string
  t: TFunction
}

const SCROLLER_ITEM_HEIGHT = 36

const ACCEPT_BTN_DATA_ACTION = 'mobileCounter-acceptBtn'
const CANCEL_BTN_DATA_ACTION = 'mobileCounter-cancelBtn'

function optionsRowRenderer(this: IRowRendererContext, { value }) {
  const { minimumAmount, unit, t } = this
  return (
    <div className={styles['optionsListRow']}>
      <Typography variant="body" className={styles['scrollerOptionText']}>
        {value} {unit}
      </Typography>
      {value === minimumAmount && (
        <>
          {' '}
          <Typography variant="smallText" color="text.secondary">
            ({t('MAIN.MIN_COUNT')})
          </Typography>
        </>
      )}
    </div>
  )
}

export const MobileCounter: FC<IMobileCounterProps> = (props) => {
  const {
    minimumAmount,
    unit,
    initialAmount = minimumAmount,
    maxAcceptableAmount,
    onAccept,
    onCancel,
    rowsCount = 5,
  } = props
  const keyboardHeight = useKeyboardHeight()
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState<string | number>(initialAmount)
  const [showNotification, setShowNotification] = useState(false)
  const inputRef = useRef<HTMLSpanElement>(null)
  const scroller = useRef<Select>(null)

  const syncInputValueWithScroller = (value: string | number) => {
    const el = inputRef.current
    if (el) el.textContent = value.toString()

    const inst = scroller.current
    if (inst) inst.setVal(value)
  }

  useEffect(() => {
    const adjustInputInitialValue = () => {
      setInputValue((base) => {
        const { nextValidAmount } = getNextValidAmount(
          Number(base),
          minimumAmount,
          maxAcceptableAmount
        )

        return nextValidAmount
      })
    }

    adjustInputInitialValue()
  }, [maxAcceptableAmount, minimumAmount])

  useEffect(() => {
    syncInputValueWithScroller(inputValue)
  }, [inputValue])

  useEffect(() => {
    /** autoFocus doesn't work for non-input field, so do it programmatically */
    inputRef.current?.focus()
  }, [])

  const handleFieldFocus = useCallback((e: FocusEvent<HTMLInputElement>) => {
    // Select all content of non-input but contenteditable element
    // https://gist.github.com/beccasaurus/2987019
    const range = document.createRange()
    range.selectNodeContents(e.target)
    const sel = window.getSelection()
    sel?.removeAllRanges()
    sel?.addRange(range)
  }, [])

  const amountSelections = useMemo(() => {
    const amounts: IDataItem[] = []
    for (let value = minimumAmount; value < maxAcceptableAmount; value += minimumAmount) {
      amounts.push({ value })
    }
    return amounts
  }, [maxAcceptableAmount, minimumAmount])

  const optionsRowContext = useMemo(() => ({ minimumAmount, unit, t }), [minimumAmount, unit, t])
  const rowRendererBound = optionsRowRenderer.bind(optionsRowContext)

  const scrollerOffsetTop = useMemo(
    () => (SCROLLER_ITEM_HEIGHT * rowsCount) / 2 - SCROLLER_ITEM_HEIGHT / 2,
    [rowsCount]
  )

  const validateAndSyncAmount = (inputContent: string) => {
    if (!inputContent) return false

    const { nextValidAmount, isValid } = getNextValidAmount(
      parseInt(inputContent),
      minimumAmount,
      maxAcceptableAmount
    )

    syncInputValueWithScroller(nextValidAmount)

    setInputValue(nextValidAmount)
    setShowNotification(!isValid)

    return isValid
  }

  const onSubmitHandler = () => {
    const value = inputRef?.current?.textContent || ''
    if (value && validateAndSyncAmount(value)) onAccept(Number(value))
  }

  return (
    <Paper
      className={clsx(styles.root, {
        [styles.withWarn]: showNotification,
        [styles.withKeyboard]: keyboardHeight > 0,
      })}
    >
      <TextField
        value={inputValue}
        variant="outlined"
        className={clsx('outlined-darkGray34K', styles.textField)}
        InputProps={{
          inputComponent: 'span',
          endAdornment: (
            <Typography className={styles.textFieldAdornment} color="text.secondary" variant="body">
              {unit}
            </Typography>
          ),
        }}
        inputProps={{
          ref: inputRef,
          size: 0,
          contentEditable: true,
          className: styles.inputElement,
          inputMode: 'numeric',
          onBeforeInput: (_e) => {
            // Allow only numbers
            const e = _e as unknown as ChangeEvent<HTMLSpanElement> & { data: string }
            const state = e.target.textContent + e.data
            const parsed = Number(state)

            if (isNaN(parsed)) return e.preventDefault()
          },
          onBlur: (e) => {
            const relatedTarget = e.relatedTarget as HTMLElement

            const shouldIgnoreBlur = () =>
              relatedTarget?.dataset.action &&
              [ACCEPT_BTN_DATA_ACTION, CANCEL_BTN_DATA_ACTION].includes(
                relatedTarget.dataset.action
              )

            if (shouldIgnoreBlur()) return

            validateAndSyncAmount(inputRef?.current?.textContent || '')
            /** Deselect everything what was selected inside the field */
            window.getSelection()?.removeAllRanges()
          },
          onFocus: handleFieldFocus,
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            onSubmitHandler()
          }
        }}
      />
      <IconButton
        data-action={CANCEL_BTN_DATA_ACTION}
        className={styles.cancel}
        icon={<SvgIcon component={CloseIcon} />}
        onClick={() => onCancel(Number(inputValue))}
      />
      <IconButton
        data-action={ACCEPT_BTN_DATA_ACTION}
        className={styles.accept}
        variant="contained"
        color="secondary"
        icon={<SvgIcon component={CheckIcon} />}
        onClick={onSubmitHandler}
      />
      {showNotification && (
        <Paper className={styles.warning}>
          <Typography variant={'smallText'}>{t('ALERTS.COUNTER_CORRECTED')}</Typography>
        </Paper>
      )}
      <div
        className={styles.optionsList}
        style={{ '--scrollerListOffset': `-${scrollerOffsetTop}px` } as CSSProperties}
      >
        <Select
          cssClass={styles['selectRoot']}
          scrollLock
          onChange={({ value }) => {
            Number.isInteger(value) && validateAndSyncAmount(String(value))
          }}
          ref={scroller}
          display="inline"
          theme="material"
          themeVariant="light"
          data={amountSelections}
          itemHeight={SCROLLER_ITEM_HEIGHT}
          rows={rowsCount}
          touchUi={true}
          renderItem={rowRendererBound}
        />
      </div>
    </Paper>
  )
}

interface IPopoverMobileCounterProps extends Omit<IMobileCounterProps, 'onCancel'> {
  open: boolean
  onClose: () => void
}

const popoverOrigin: PopoverOrigin = {
  vertical: 'bottom',
  horizontal: 'center',
}

export const PopoverMobileCounter: FC<IPopoverMobileCounterProps> = (props) => {
  const { open, onClose, ...counter } = props
  const keyboardHeight = useKeyboardHeight()

  return (
    <Popover
      transitionDuration={0}
      transformOrigin={popoverOrigin}
      anchorOrigin={popoverOrigin}
      open={open}
      classes={{ paper: styles.popover }}
      keepMounted={false}
      style={{ bottom: keyboardHeight }}
    >
      <MobileCounter {...counter} onCancel={onClose} />
    </Popover>
  )
}
