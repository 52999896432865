import { Stocks } from '@obeta/data/lib/hooks/useStocksMap'
import { ProductType } from '@obeta/models/lib/models/Article/Shop/Product'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { ArticleStock } from '../articles/ArticleStock'
import styles from './StockInfo.module.scss'
import { StockAvailabilityEstimate } from '@obeta/schema'

interface IStockInfoProps {
  direction: 'horizontal' | 'vertical'
  loading?: boolean
  productType: ProductType
  productAmount: number
  storeAddress: string
  stocks: Stocks
  stockAvailabilityEstimate?: StockAvailabilityEstimate | null
}

export const StockInfo: React.FC<IStockInfoProps> = React.memo(function StockInfo(props) {
  const {
    stocks,
    productType,
    storeAddress,
    productAmount,
    loading = false,
    direction,
    stockAvailabilityEstimate = null,
  } = props
  const { t } = useTranslation()

  const onlineStock = stocks?.['online']
  const storeStock = stocks?.['store']

  const l = loading || !stocks

  return (
    <div className={clsx(styles.statuses, styles[direction])}>
      <ArticleStock
        productAmount={productAmount}
        name={t<string>('MAIN.ONLINE')}
        stockType={'online'}
        storeAddress={storeAddress}
        stock={onlineStock?.stock}
        stockAvailabilityEstimate={stockAvailabilityEstimate}
        available={Boolean(onlineStock?.available)}
        productType={productType}
        loading={l}
      />
      <ArticleStock
        productAmount={productAmount}
        name={t<string>('MAIN.STORE')}
        stockType={'store'}
        storeAddress={storeAddress}
        stock={storeStock?.stock}
        stockAvailabilityEstimate={stockAvailabilityEstimate}
        available={Boolean(storeStock?.available)}
        productType={productType}
        loading={l}
      />
    </div>
  )
})
