import { Typography } from '@mui/material'
import { composeReactRefs } from '@obeta/utils/lib/composeReactRefs'
import clsx from 'clsx'
import React, { useLayoutEffect, useRef, useState } from 'react'
import styles from './TypographyLineClamp.module.scss'
import useResizeObserver from '@react-hook/resize-observer'

interface ITypographyLineClampProps extends React.ComponentProps<typeof Typography> {
  maxElHeight?: number // "maxHeight" leads to ts error
  lines?: number
  enforceHeight?: boolean
}

const calcDimensions = (el: Element, maxHeight: number, _lines: number | undefined) => {
  const lineHeight = parseFloat(getComputedStyle(el).lineHeight)
  let lines = 0
  if (_lines) {
    lines = _lines
  } else {
    lines = Math.floor(maxHeight / lineHeight)
  }
  return { lines, height: `${lines * lineHeight}px` }
}

export const TypographyLineClamp = React.forwardRef<HTMLSpanElement, ITypographyLineClampProps>(
  function TypographyLineClamp(props, ref) {
    const {
      maxElHeight: maxHeight = 0,
      className,
      lines: _lines,
      enforceHeight,
      ...restProps
    } = props

    const typographyRef = useRef<HTMLSpanElement>(null)
    const [dimensions, setDimensions] = useState({
      lines: _lines || 3,
      height: 'initial', // make sure element is not collapsed before measure calculations are done after fitst render
    })

    useLayoutEffect(() => {
      const el = typographyRef.current
      if (!el) {
        return
      }

      const d = calcDimensions(el, maxHeight, _lines)
      setDimensions(d)
    }, [maxHeight, _lines])

    useResizeObserver(typographyRef, (entry) => {
      const target = entry.target
      const d = calcDimensions(target, maxHeight, _lines)
      setDimensions(d)
    })

    return (
      <Typography
        ref={composeReactRefs(ref, typographyRef)}
        className={clsx(styles.root, styles.experimentalClamp, className)}
        {...restProps}
        style={{
          ...restProps.style,
          WebkitLineClamp: dimensions.lines,
          maxHeight: dimensions.height,
          minHeight: enforceHeight ? dimensions.height : undefined,
          height: 'auto',
        }}
      ></Typography>
    )
  }
)
