import React, { FC, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { SvgIcon, useTheme } from '@mui/material'
import { ReactComponent as StrikeThroughCartIcon } from 'assets/icon/designsystem/remove_shopping_cart.svg'
import { useBreakpoints } from '@obeta/data/lib/hooks/useBreakpoints'
import { useIdsProductTransfer } from '@obeta/data/lib/hooks/ids/useIdsProductTransfer'
import { SecondaryButton, TertiaryButton } from '../custom-button/CustomButton'

interface Props {
  className?: string
  sapId: string
  amount: number
  offerId?: string
  offerItemPosition?: number
  variant?: 'secondary' | 'tertiary'
}

export const IdsTransferProductButton: FC<Props> = ({
  className,
  amount,
  sapId,
  offerItemPosition,
  offerId = '',
  variant = 'tertiary',
}) => {
  const { t } = useTranslation()
  const { desktop } = useBreakpoints()
  const theme = useTheme()
  const transferIdsProductAndSubmit = useIdsProductTransfer()

  const onClick = useCallback(
    (event) => {
      event.stopPropagation()
      event.preventDefault()
      transferIdsProductAndSubmit([
        {
          amount,
          sapId,
          offerId,
          offerItemPosition: offerItemPosition ? offerItemPosition.toString() : '',
        },
      ])
    },
    [amount, offerId, offerItemPosition, sapId, transferIdsProductAndSubmit]
  )
  return (
    <div className={className}>
      {variant === 'tertiary' ? (
        <TertiaryButton
          size={desktop ? 'small' : 'large'}
          leftIcon={<SvgIcon component={StrikeThroughCartIcon} />}
          onClick={onClick}
        >
          {t('IDS.TRANSFER_BACK')}
        </TertiaryButton>
      ) : (
        <SecondaryButton
          fullWidth
          type="submit"
          leftIcon={
            <SvgIcon htmlColor={theme.palette.white.main} component={StrikeThroughCartIcon} />
          }
          onClick={onClick}
        >
          {t('IDS.TRANSFER_BACK')}
        </SecondaryButton>
      )}
    </div>
  )
}
