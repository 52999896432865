import { CardContent } from '@mui/material'
import clsx from 'clsx'
import React from 'react'
import { LightGrayDivider } from '../../light-gray-divider/LightGrayDivider'
import { ActionsWrapper } from './Actions'
import { Bottom } from './Bottom'
import { ProductImage } from './ProductImage'
import { IBaseLayout, IAdornments } from './types'
import styles from './Vertical.module.scss'

type BlockType = 'properties' | 'statuses' | 'values'

export interface IVerticalLayout {
  dividers: Record<BlockType, boolean>
  order: Record<BlockType, number>
  actionsPlacement: 'top' | 'right'
}

interface IVerticalLayoutProps extends IBaseLayout, IAdornments {
  className?: string
  layoutConfig: IVerticalLayout
  values: JSX.Element
  titleLines?: number
  stackStatus?: boolean
}

export const Vertical: React.FC<IVerticalLayoutProps> = (props) => {
  const {
    className,
    layoutConfig,
    imageElement,
    titleElement,
    propertiesElements,
    labels,
    stockElements: statusesElement,
    counter,
    topAdornments,
    values,
    bottomAdornments,
    actionsElements,
    stackStatus = false,
  } = props

  const actionsPlacement = layoutConfig?.actionsPlacement || 'top'
  const top = actionsPlacement === 'top'
  const actionsWrapperElement =
    actionsElements && actionsElements.length > 0 ? (
      <ActionsWrapper layout={top ? 'horizontal' : 'vertical'}>{actionsElements}</ActionsWrapper>
    ) : null

  const contentSlots = [
    {
      type: 'properties',
      element: <div className={styles.verticalProperties}>{propertiesElements}</div>, // TODO: layout?
    },
    {
      type: 'values',
      element: values,
    },
    {
      type: 'statuses',
      element: statusesElement ? (
        <div className={clsx({ [styles.withLabels]: labels, [styles.stacked]: stackStatus })}>
          {statusesElement}
          {(labels || stackStatus) && (
            <>
              {!stackStatus && <LightGrayDivider orientation="vertical" />}
              {stackStatus ? <span className={styles.labelwrap}>{labels}</span> : labels}
            </>
          )}
        </div>
      ) : null,
    },
  ]

  return (
    <CardContent className={clsx(styles.layout, className)}>
      {actionsWrapperElement && (
        <div className={styles.actionsWrapper}>{actionsWrapperElement}</div>
      )}
      <ProductImage size={'128'}>{imageElement}</ProductImage>
      <div className={styles.header}>
        {(Array.isArray(topAdornments) ? topAdornments.length > 0 : topAdornments) && (
          <div className={styles.topAdornments}>{topAdornments}</div>
        )}
        {titleElement}
      </div>
      {contentSlots.map((conf) => {
        if (!conf.element) {
          return null
        }

        if (!layoutConfig) {
          return conf.element
        }

        return (
          <div key={conf.type} style={{ order: layoutConfig.order[conf.type] }}>
            {conf.element}
            {layoutConfig.dividers[conf.type] && (
              <LightGrayDivider className={styles.divider} orientation="horizontal" />
            )}
          </div>
        )
      })}
      {(counter || (bottomAdornments && bottomAdornments.length > 0)) && (
        <Bottom className={styles.verticalCardLayoutBottom}>
          {counter}
          {bottomAdornments}
        </Bottom>
      )}
    </CardContent>
  )
}
