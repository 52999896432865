import { constructCatId } from '@obeta/utils/lib/categories'
import { useArticlesSearchProvider } from './useArticleSearchProvider'

export const useSearchPageMeta = () => {
  const { searchParams, hits, isFetching } = useArticlesSearchProvider()

  // in case user didn't used his own input to dispatch search show category as fallback
  const title =
    searchParams?.searchString ||
    searchParams?.obetaCategory?.name ||
    searchParams?.customTitle ||
    ''
  const hitCount = Math.max(0, hits || 0)
  const _rootId = searchParams?.obetaCategory?.rootId
  const rootId = _rootId ? constructCatId(_rootId) : null
  return { title, rootId, loading: Boolean(isFetching), hitCount, searchParams }
}
