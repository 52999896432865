import { ProductStockStatus } from '@obeta/models/lib/models/Article/Shop/Product'

/** stock doesn't exist if value is null */
export type StockAmount = number | null

export const getProductStockStatus = (
  inStock: StockAmount,
  requestedAmount = 0
): ProductStockStatus => {
  if (inStock === null) {
    return 'not-available'
  }

  if (inStock >= requestedAmount) {
    if (inStock === 0) {
      return 'not-for-sale'
    }
    return 'available'
  }

  if (inStock > 0) {
    return 'partially-available'
  }

  return 'currently-not-available'
}
