import { Typography } from '@mui/material'
import clsx from 'clsx'
import React, { useState } from 'react'
import { TypographyLineClamp } from '../typography'
import styles from './Title.module.scss'
import useResizeObserver from '@react-hook/resize-observer'

interface IBaseTitleProps extends React.ComponentProps<typeof Typography> {
  lines?: number
  maxElHeight?: number
  enforceHeight?: boolean //if true the linecap height will be enforced even if not enough text is beeing provided
}

interface ITitleProps extends Omit<IBaseTitleProps, 'maxElHeight'> {
  productImageRef: React.RefObject<HTMLElement>
}

export const BaseTitle = React.forwardRef<HTMLElement, IBaseTitleProps>((props, ref) => {
  const { variant = 'boldText', children, className, lineHeight, ...restProps } = props

  return (
    <TypographyLineClamp
      ref={ref}
      className={clsx(styles.title, className)}
      variant={variant}
      lineHeight={lineHeight}
      {...restProps}
    >
      {children}
    </TypographyLineClamp>
  )
})

/**
 * TODO: allow fixed height
 *
 * One of the requirements is to make sure that Title is not bigger than
 * ProductImage. In case Title becomes bigger -> crop it and show "..."
 * Restrictions: I can't show content like "hey...hoo" with "TypographyLineClamp"
 * component as it has "white-space: normal" setting. Instead I show "hey..."
 * @deprecated we don't need this anymore
 */
export const Title = React.forwardRef<HTMLElement, ITitleProps>(function Title(props, ref) {
  const { productImageRef, children, ...restProps } = props
  const [maxHeight, setMaxHeight] = useState(0)

  useResizeObserver(productImageRef, (entry) => {
    const dim = entry.contentRect
    setMaxHeight(dim.height)
  })

  return (
    <BaseTitle ref={ref} maxElHeight={maxHeight} {...restProps}>
      {children}
    </BaseTitle>
  )
})
