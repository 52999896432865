import React from 'react'
import { ArticleAmount } from '../articles/ArticleAmount'
import { Counter } from '../counter/Counter'
import { BaseCardCounterProps, IBaseProductCard } from './baseTypes'

export interface ICardWithCounterProps {
  counter: BaseCardCounterProps
  productUnit: string | null
  minimumAmount: number
}

export type IWithCounter<
  Props extends Pick<IBaseProductCard, 'counter' | 'authenticated' | 'productAmount'>
> = Omit<Props, 'counter'> & ICardWithCounterProps

export const withBaseCounter = <
  Props extends Pick<IBaseProductCard, 'counter' | 'authenticated' | 'productAmount'>
>(
  Card: React.FC<Props>
): React.FC<IWithCounter<Props>> => {
  return function WithBaseCount(props) {
    const {
      counter: counterProps,
      productUnit,
      authenticated,
      minimumAmount,
      productAmount,
      children,
      ...restProps
    } = props

    const CounterComponent = productUnit !== null ? ArticleAmount : Counter
    const counter = authenticated ? (
      <CounterComponent
        {...counterProps}
        key="counter"
        minimumAmount={minimumAmount}
        amount={productAmount}
        unit={productUnit || ''}
        stretchHorizontal={true}
      />
    ) : null

    /**
     * TODO: I need to figure out better way to write enhanced with proper types
     * (without type assertions)
     */

    return (
      <Card
        {...(restProps as unknown as Props)}
        authenticated={authenticated}
        productAmount={productAmount}
        counter={counter}
      />
    )
  }
}
