import clsx from 'clsx'
import { LightGrayDivider } from '../../light-gray-divider/LightGrayDivider'
import { LayoutType } from './types'
import styles from './Values.module.scss'

export interface IValuesContainerProps {
  values: JSX.Element[]
  layoutType: LayoutType
  hideOldPriceElement?: boolean
}

export const ValuesContainer: React.FC<IValuesContainerProps> = (props) => {
  const { layoutType, values, hideOldPriceElement } = props

  return (
    <div
      className={clsx(styles.values, styles[layoutType], {
        [styles.resetFixedHeight]: hideOldPriceElement,
      })}
    >
      {' '}
      {values.reduce<React.ReactChild[]>((acc, current, idx) => {
        const last = idx >= values.length - 1
        acc.push(current)
        if (last) {
          return acc
        }
        acc.push(
          <LightGrayDivider
            key={`divider-${idx}`}
            orientation={layoutType === 'vertical' ? 'horizontal' : 'vertical'}
          />
        )
        return acc
      }, [])}
    </div>
  )
}
