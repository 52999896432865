import { Stocks } from './useStocksMap'

type Result = {
  isReplacementArticleVisible: boolean
  replacementArticleHref: string
}
type Hook = {
  (options: { replacementArticleSapId: string; stocks: Stocks }): Result
  (options: {
    multipleReplacementArticleOptions: {
      dehaId?: string
      replacementDehaIds: string[]
    }
    stocks: Stocks
  }): Result
}
export const useReplacementArticle: Hook = ({
  replacementArticleSapId,
  stocks,
  multipleReplacementArticleOptions,
}) => {
  const showMultipleReplacementArticles = Boolean(
    multipleReplacementArticleOptions?.dehaId &&
      multipleReplacementArticleOptions.replacementDehaIds.length > 0
  )

  if (!replacementArticleSapId && !showMultipleReplacementArticles) {
    return {
      isReplacementArticleVisible: false,
      replacementArticleHref: '',
    }
  }
  const stockAmountOnline = stocks?.['online']?.stock?.amount
  const stockAmountStore = stocks?.['store']?.stock?.amount
  const showReplacementArticle = !stockAmountOnline && !stockAmountStore

  return {
    isReplacementArticleVisible: showReplacementArticle,
    replacementArticleHref: `/article-detail/${replacementArticleSapId}`,
  }
}
