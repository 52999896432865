import { ShoppingCartItem } from '@obeta/models/lib/models/ShoppingCart/ShoppingCart'
import { ArticleAmount, ArticleAmountProps } from './ArticleAmount'
import { useProductCard } from '@obeta/data/lib/hooks/useProductCard'

interface IShoppingCartCounterProps extends ArticleAmountProps {
  shoppingCartItem: Pick<ShoppingCartItem, 'id' | 'cartId'>
  disableCounter?: boolean
  onCounterSubmit?: (amount: number, userInputAmount: number) => void
}

export const ShoppingCartCounter: React.FC<IShoppingCartCounterProps> = (props) => {
  const {
    onCounterSubmit,
    onInputValidation: _onInputValidation,
    shoppingCartItem,
    unit,
    ...rest
  } = props
  const { setAmountRoundUpNotification } = useProductCard()

  const onInputValidation: typeof _onInputValidation = (valid, amountToUse, amount) => {
    _onInputValidation?.(valid, amountToUse, amount)

    // Display notification if user input amount is below minimum amount
    if (!valid && unit) setAmountRoundUpNotification(shoppingCartItem.id, amountToUse, amount, unit)
  }

  return (
    <ArticleAmount
      unit={unit}
      {...rest}
      onCounterSubmit={onCounterSubmit}
      onInputValidation={onInputValidation}
    />
  )
}
