import aa from 'search-insights'
import {
  ALGOLIA_INDEX_NAME,
  ALGOLIA_REPLICA_INDEX_NAME,
  ALGOLIA_APPLICATION_ID,
  ALGOLIA_SEARCH_API_KEY,
} from './config'

const initAlgolia = () => {
  aa('init', {
    appId: ALGOLIA_APPLICATION_ID as string,
    apiKey: ALGOLIA_SEARCH_API_KEY as string,
  })
}

export const sendClickedObjectIdAfterSearchEvent = (
  algoliaUserToken: string,
  queryId: string,
  sapId: string,
  index: number
) => {
  initAlgolia()
  index++ // //algolia needs the positions to start at 1, not 0

  aa('clickedObjectIDsAfterSearch', {
    userToken: algoliaUserToken,
    eventName: 'Product clicked',
    index: ALGOLIA_INDEX_NAME as string,
    queryID: queryId,
    objectIDs: [sapId],
    positions: [index],
  })

  if (ALGOLIA_REPLICA_INDEX_NAME !== 'none') {
    aa('clickedObjectIDsAfterSearch', {
      userToken: algoliaUserToken,
      eventName: 'Product clicked',
      index: ALGOLIA_REPLICA_INDEX_NAME as string,
      queryID: queryId,
      objectIDs: [sapId],
      positions: [index],
    })
  }
}

export const sendClickedObjectIdAfterConversionEvent = (
  algoliaUserToken: string,
  queryId: string,
  sapId: string
) => {
  initAlgolia()
  aa('convertedObjectIDsAfterSearch', {
    userToken: algoliaUserToken,
    eventName: 'Product converted',
    index: ALGOLIA_INDEX_NAME as string,
    queryID: queryId,
    objectIDs: [sapId],
  })

  if (ALGOLIA_REPLICA_INDEX_NAME !== 'none') {
    aa('convertedObjectIDsAfterSearch', {
      userToken: algoliaUserToken,
      eventName: 'Product converted',
      index: ALGOLIA_REPLICA_INDEX_NAME as string,
      queryID: queryId,
      objectIDs: [sapId],
    })
  }
}
